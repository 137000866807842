import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import Flickity from 'react-flickity-component'

import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import Mainheader from '../../components/MainHeader';
import Footer from '../../components/Footer';
import '../../styles/work/detail.css';
import '../../styles/work/codezeros/index.css';
import '../../styles/plugins/flickity.css'

// images
import CodezerosLogo from '../../images/work/logos/codezeros.svg';
import OrangeIcon from '../../images/icons/orange.png';
import SeedlingIcon from '../../images/icons/seedling.png';
import IntroVideo from '../../images/work/codezeros/1.mp4';
import IntroVideoPoster from '../../images/work/codezeros/1.jpg';
import VideoCassetteIcon from '../../images/icons/video_cassette.png';
import DiamondSuitIcon from '../../images/icons/diamond_suit.png';
import KeyIcon from '../../images/icons/key.png';
import CreativeDirectionArt from '../../images/work/codezeros/creative_direction_art.svg';
import ConnectingLineArt from '../../images/work/codezeros/connecting_line.svg';
import GrapesIcon from '../../images/icons/grapes.png';
import RulerIcon from '../../images/icons/ruler.png';
import CodezerosBrandmark from '../../images/work/logos/codezeros_brandmark.svg';
import SpeakingHeadIcon from '../../images/icons/speaking_head.png';
import SymbolsVideo from '../../images/work/codezeros/5a.mp4';
import SymbolsVideoPoster from '../../images/work/codezeros/5a.jpg';
import WrenchIcon from '../../images/icons/wrench.png';
import RacingCarIcon from '../../images/icons/racing_car.png';
import HomepageScrollVideo from '../../images/work/codezeros/8b.mp4';
import TechnologySectionVideo from '../../images/work/codezeros/8c.mp4';
import ServicesListVideo from '../../images/work/codezeros/8d.mp4';
import HeroSectionVideo from '../../images/work/codezeros/8e.mp4';

import WorkOutro from '../../components/work/WorkOutro';

const Codezeros = () => {
    gsap.registerPlugin(ScrollTrigger);

    const [scrollProgress, setScrollProgress] = useState(0);

    useLayoutEffect(() => {
        let masterTl = gsap.timeline({});

        let horizontalGroupTl = gsap.timeline({});
        horizontalGroupTl.to(".horizontal-group-wrapper", {
            x: "-50%",
            ease: "none",
            scrollTrigger: {
                trigger: ".horizontal-group",
                start: "top top",
                end: "+=200%",
                pin: true,
                anticipatePin: 1,
                pinSpacing: true,
                scrub: 0.5,
            }
        });


        let progress = 0;

        let progressTl = gsap.timeline({});
        progressTl.to(progress, {
            progress: 1,
            duration: 1,
            ease: "none",
            scrollTrigger: {
                start: "top top",
                trigger: ".wd-wrapper",
                endTrigger: outroRef.current,
                end: "top bottom",
                invalidateOnRefresh: true,
                scrub: true,
                onUpdate: (self) => setScrollProgress(self.progress.toFixed(2)),
            }
        });

        masterTl.add(horizontalGroupTl);
        masterTl.add(progressTl);


        // cleanup
        return () => {
            masterTl.kill();
        };
    }, []);

    const workProgressBar = useRef();
    const progressTextSource = "Branding service for an emerging technology company";
    const progressTextArray = progressTextSource.split("");
    const progressLength = progressTextArray.length;

    const getSplitText = (percentage) => {
        let progress = progressTextArray.slice(0, progressLength * percentage);
        let pending = progressTextArray.slice(progressLength * percentage, progressLength);
        return [progress.join(""), pending.join("")];
    }

    let fadeVariants = {
        initial: {
            opacity: 0,
            y: "2%",
        },
        animate: {
            opacity: 1,
            y: "0%",
            transition: {
                duration: 0.4,
                ease: "circOut",
            }
        }
    };

    let klTableVariants = {
        initial: {
            opacity: 0,
            x: 10,
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.4,
                ease: "circOut",
                staggerChildren: 0.05,
            }
        }
    };

    const researchRef = useRef();
    const directionRef = useRef();
    const creativeDirectionRef = useRef();
    const symbolsRef = useRef();
    const interfaceRef = useRef();
    const footnoteRef = useRef();
    const outroRef = useRef();


    // hide progressbar when outro is in view
    useLayoutEffect(() => {
        let animation = gsap.to(workProgressBar.current, {
            opacity: 0,
            ease: "power4.out",
            scrollTrigger: {
                trigger: outroRef.current,
                scrub: 0.25,
                end: "top 90%"
            }
        });

        // cleanup
        return () => {
            animation.kill();
        };
    }, []);

    // gallery
    const galleryRef = useRef(null);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const galleryCaptions = [
        "Another landing page screen",
        "Codezeros homepage scroll",
        "Technology section of Codezeros",
        "Service-list of Codezeros",
        "Hero section of Codezeros",
    ];
    const galleryOptions = {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: false,
        setGallerySize: false,
        wrapAround: true,
        selectedAttraction: 0.1,
        friction: 0.8,
    };

    // set current gallery index
    useEffect(() => {
        galleryRef.current.on('change', () => {
            setGalleryIndex(galleryRef.current.selectedIndex);
        });

        return () => {
            galleryRef.current.off('change');
            galleryRef.current.destroy();
        }
    }, []);


    // pause video playback when not in view
    useEffect(() => {
        let observerOptions = {
            rootMargin: "0px",
            threshold: [0.25, 0.75] // start playing when 25% and 75% of the element is in view
        };

        // watch all selected videos and control playback when they enter the viewport
        let handlePlay = (entries, videoObserver) => {
            entries.forEach((entry) => {
                let videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            });
        };

        let videoObserver = new IntersectionObserver(handlePlay, observerOptions);

        // select all the videos on page and observe them
        let videos = document.querySelectorAll("video");
        videos.forEach((video) => {
            videoObserver.observe(video);
        });

        // cleanup
        return () => {
            videoObserver.disconnect();
        }
    }, []);

    return (
        <Styleswrapper
            bgColor1="#290808"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="35%"
            noise="true"
        >
            <PageMeta
                theme="#290808"
                title="Codezeros — Nihar Bhagat"
                description="A case study of how we influenced the culture of a Web3 company before Web3 was coined"
                image="../images/social/work/codezeros/default.jpg"
                twitterImage="../images/social/work/codezeros/twitter.jpg"
            />

            <Mainheader
                bgColor1="#290808"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="35%"
                currentPage={'work'}
                noise="true"
            />

            <article className="wd-wrapper codezeros">

                {/* I  N  T  R  O */}
                <section className="wd-block intro">
                    <motion.div
                        className="wd-project-logo"
                        variants={fadeVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <img src={CodezerosLogo}
                            alt="Codezeros"
                            className="icon"
                            loading="eager"
                            draggable="false" />
                    </motion.div>

                    <motion.div
                        className="wd-cc"
                        variants={fadeVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <div className="title">
                            <h2>Introduction</h2>
                            <img src={OrangeIcon}
                                alt="🍊"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Codezeros originally commissioned this project for a logo and a website
                            </p>
                            <span className="divider"></span>
                            <p>
                                We discovered the need to radically separate this company from all other IT companies. Blockchain was considered nothing more than a concept back in 2018.
                            </p>
                        </div>
                    </motion.div>

                    <motion.div
                        className="wd-cc wd-cc-emoji"
                        variants={fadeVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <div className="title">
                            <img src={SeedlingIcon}
                                alt="🌱"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p>
                                Codezeros was the parent company of the branding studio I was working at. We shared the same office floor and used to provide minor Design support. Eventually, I was offered the role of Chief Brand Officer for Codezeros alongside my position at Slangbusters.
                            </p>
                        </div>
                    </motion.div>

                    <div className="wd-media-wrapper">
                        <motion.div
                            className="wd-media"
                            variants={fadeVariants}
                            initial="initial"
                            animate="animate"
                        >
                            <video
                                draggable="false"
                                loading="eager"
                                className="wd-media-img"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                poster={IntroVideoPoster}
                                title="Overview shot of cryptocurrency coins on a green cutting mat"
                                src={IntroVideo} />
                        </motion.div>
                    </div>
                </section>

                {/* R  E  S  E  A  R  C  H */}
                <section className="wd-block research" ref={researchRef}>
                    <div className="wd-media-wrapper">
                        <motion.div
                            className="wd-media"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: researchRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <StaticImage
                                src="../../images/work/codezeros/2.jpg"
                                width={1600}
                                alt="Entrance door with codezeros brandmark vinyl"
                                className="wd-media-img"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                                transformOptions={{
                                    cropFocus: "north",
                                }}
                            />
                        </motion.div>
                    </div>

                    <motion.div
                        className="wd-cc"
                        variants={fadeVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            root: researchRef.current,
                            margin: "0% 0% -10% 0%",
                        }}
                    >
                        <div className="title">
                            <h2>Research</h2>
                            <img src={VideoCassetteIcon}
                                alt="📼"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                It was like researching 'internet companies' in the 80s
                            </p>
                            <span className="divider"></span>
                            <p>
                                There were no other companies (not just in India but on this whole planet) that delivered Blockchain services to people that wanted to build a product using that technology.
                                <br /><br />
                                We started looking for reports but found no literature on the subject. Not even a single syndicated research. So we compiled our own report.
                            </p>
                        </div>
                    </motion.div>

                    <motion.div
                        className="wd-cc wd-cc-emoji"
                        variants={fadeVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            amount: 0.2,
                        }}
                    >
                        <div className="title">
                            <img src={DiamondSuitIcon}
                                alt="♦️"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p>
                                During the process, we learned about how blockchain functions as a technology and its potential use-cases. We constantly verified it all with the Blockchain Lead of Codezeros, Mahendra aka Ace of Diamonds
                                <img src={DiamondSuitIcon}
                                    alt="♦️"
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </p>
                        </div>
                    </motion.div>
                </section>

                {/* D  I  R  E  C  T  I  O  N */}
                <section className="wd-block direction" ref={directionRef}>
                    <div className="wd-media-wrapper">
                        <motion.div
                            className="wd-media"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: directionRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <StaticImage
                                src="../../images/work/codezeros/3.jpg"
                                width={2448}
                                alt="Sheets of paper with brand brief and some brandmark iterations"
                                className="wd-media-img"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                        </motion.div>
                    </div>

                    <motion.div
                        className="wd-cc"
                        variants={fadeVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            root: directionRef.current,
                            margin: "0% 0% -10% 0%",
                        }}
                    >
                        <div className="title">
                            <h2>Strategic Direction</h2>
                            <img src={VideoCassetteIcon}
                                alt="📼"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Building a working methodology blueprint for a Web3 service provider
                            </p>
                            <span className="divider"></span>
                            <p>
                                While the industry was mostly unknown, vague interpretations of new technology had started to pop up. IT service companies around us were using the same old techniques to define and market themselves in the context of blockchain.
                                <br /><br />
                                We did not know the possibilities too but we knew what we had to stay away from. The way forward was inspiring. Total creative liberty in a time of rusted methodologies. This was the perfect excuse to pivot to a totally new way of working.
                            </p>
                        </div>
                    </motion.div>
                </section>

                <div className="horizontal-group">
                    <div className="horizontal-group-wrapper">
                        {/* C  R  E  A  T  I  V  E     D  I  R  E  C  T  I  O  N */}
                        <section className="wd-block creative-direction" ref={creativeDirectionRef}>
                            <motion.div
                                className="wd-cc wd-cc-golden"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: creativeDirectionRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <div className="title">
                                    <h2>Creative Direction</h2>
                                    <img src={KeyIcon}
                                        alt="🔑"
                                        className="icon"
                                        loading="eager"
                                        draggable="false" />
                                </div>
                                <div className="copy">
                                    <span className="divider"></span>
                                    <p className="highlight">
                                        This project allowed me to implement experimental methods in a conservative work environment, guided by Strategy.
                                        <br /><br />
                                        The lack of a specific style was fulfilled by Modernism. It too focused on the usefulness of ideas and blended with the vision of Codezeros like the AirPods fit in its case.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                className="wd-art"
                                variants={fadeVariants}
                                initial={{ opacity: 0 }}
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: directionRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <img src={CreativeDirectionArt}
                                    alt=""
                                    loading="eager"
                                    draggable="false" />
                            </motion.div>
                            <motion.div
                                className="wd-connecting-line"
                                variants={fadeVariants}
                                initial={{ opacity: 0 }}
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: directionRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <img src={ConnectingLineArt}
                                    alt=""
                                    loading="eager"
                                    draggable="false" />
                            </motion.div>
                        </section>

                        {/* S  E  R  V  I  C  E  S */}
                        <section className="wd-block services">
                            <div className="first-column">
                                <motion.div
                                    className="wd-cc"
                                    variants={fadeVariants}
                                    initial={{ x: 10, y: 0, opacity: 0 }}
                                    whileInView="animate"
                                    viewport={{
                                        once: true,
                                        amount: 0.2,
                                    }}
                                >
                                    <div className="title">
                                        <h2>Defining Services</h2>
                                        <img src={GrapesIcon}
                                            alt="🍇"
                                            className="icon"
                                            loading="eager"
                                            draggable="false" />
                                    </div>
                                    <div className="copy">
                                        <p className="highlight">
                                            (A use-case of brand strategy having immediate impact on business)
                                        </p>
                                        <span className="divider"></span>
                                        <p>
                                            The services were not clearly defined and since no company in the world was delivering blockchain solutions, no references to begin with.
                                            <br /><br />
                                            We had the data from our own reports. We sat down with Operations and defined them services.
                                        </p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    className="wd-kl-table"
                                    variants={klTableVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    viewport={{
                                        once: true,
                                        amount: 0.2,
                                    }}
                                >
                                    <motion.p className="title"
                                        variants={fadeVariants}
                                    >
                                        The KL Table
                                    </motion.p>
                                    <motion.p className="head"
                                        variants={fadeVariants}
                                    >
                                        <span>KL</span>
                                        <span>Who is it?</span>
                                    </motion.p>
                                    <motion.p className='item'
                                        variants={fadeVariants}
                                    >
                                        <span>03</span>
                                        <span>Blockchain = Bitcoin</span>
                                    </motion.p>
                                    <motion.p className='item'
                                        variants={fadeVariants}
                                    >
                                        <span>02</span>
                                        <span>Ethereum exists</span>
                                    </motion.p>
                                    <motion.p className='item'
                                        variants={fadeVariants}
                                    >
                                        <span>01</span>
                                        <span>Business owners who are abreast of the technology. The ideal customer.</span>
                                    </motion.p>
                                    <motion.p className="footer"
                                        variants={fadeVariants}
                                    >
                                        <span>00</span>
                                        <span>Codezeros</span>
                                    </motion.p>
                                </motion.div>
                            </div>
                            <div className="second-column">
                                <div className="wd-media-wrapper">
                                    <motion.div
                                        className="wd-media"
                                        variants={fadeVariants}
                                        initial={{ x: 10, y: 0, opacity: 0 }}
                                        whileInView="animate"
                                        viewport={{
                                            once: true,
                                            amount: 0.2,
                                        }}
                                    >
                                        <StaticImage
                                            src="../../images/work/codezeros/4.jpg"
                                            width={1200}
                                            alt="Screenshot of services offered by codezeros"
                                            className="wd-media-img"
                                            loading="lazy"
                                            draggable="false"
                                            placeholder="blurred"
                                            quality={100}
                                        />
                                    </motion.div>
                                </div>
                                <motion.div
                                    className="wd-cc"
                                    variants={fadeVariants}
                                    initial={{ x: 10, y: 0, opacity: 0 }}
                                    whileInView="animate"
                                    viewport={{
                                        once: true,
                                        amount: 0.2,
                                    }}
                                >
                                    <div className="title">
                                        <h2>Knowledge Levels</h2>
                                        <img src={RulerIcon}
                                            alt="📏"
                                            className="icon"
                                            loading="eager"
                                            draggable="false" />
                                    </div>
                                    <div className="copy">
                                        <p className="highlight">
                                            (A use-case of brand strategy having immediate impact on business) (1)
                                        </p>
                                        <span className="divider"></span>
                                        <p>
                                            We knew the terminology and the way we had structured the services but the users did not. Nobody did. So we created a tool to serve as a bridge: Knowledge Levels.
                                            <br /><br />
                                            (Based on this gradation system, we classified the audience for each page on the website too.)
                                        </p>
                                    </div>
                                </motion.div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* S  Y  M  B  O  L  S */}
                <section className="wd-block symbols" ref={symbolsRef}>
                    <div className="first-column">
                        <motion.div
                            className="wd-cc"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                root: symbolsRef.current,
                                margin: "0% 0% -10% 0%",
                            }}
                        >
                            <div className="title">
                                <h2>Symbols</h2>
                                <img src={CodezerosBrandmark}
                                    alt=""
                                    className="icon"
                                    loading="eager"
                                    draggable="false" />
                            </div>
                            <div className="copy">
                                <p className="highlight">
                                    Yet another unique arrow
                                </p>
                                <span className="divider"></span>
                                <p>
                                    There was silence in the studio. Accenture had taken the last arrow to put on logos. Quirky logos all over icodrops.com (an ICO database) were shaping the visual ecosystem of blockchain entities. We turned to the Brand Brief of Codezeros.
                                    <br /><br />
                                    The essence and positioning was this. An emerging tech company with an idea-centered focus.
                                    <br /><br />
                                    The idea was to create a high performance signature. A true symbol that represents all of the things you just read.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                    <div className="second-column">
                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: symbolsRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <video
                                    draggable="false"
                                    loading="eager"
                                    className="wd-media-img"
                                    autoPlay="autoplay"
                                    playsInline="playsinline"
                                    muted="muted"
                                    poster={SymbolsVideoPoster}
                                    title="Animation of codezeros logo reveal"
                                    src={SymbolsVideo} />

                            </motion.div>

                            <motion.div
                                className="wd-cc wd-cc-emoji"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 0.2,
                                }}
                            >
                                <div className="title">
                                    <img src={SpeakingHeadIcon}
                                        alt="🗣️"
                                        className="icon"
                                        loading="eager"
                                        draggable="false" />
                                </div>
                                <div className="copy">
                                    <p>
                                        Is it read code-zeros, co-dezeros, codez-eros? I charged the logotype with the responsibility to remove this ambiguity.
                                    </p>
                                </div>
                            </motion.div>
                        </div>

                        <div className="wd-media-wrapper">
                            <motion.div
                                className="wd-media"
                                variants={fadeVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    root: symbolsRef.current,
                                    margin: "0% 0% -10% 0%",
                                }}
                            >
                                <StaticImage
                                    src="../../images/work/codezeros/5b.jpg"
                                    width={1900}
                                    alt="Final iterations of codezeros brandmark"
                                    className="wd-media-img"
                                    loading="lazy"
                                    draggable="false"
                                    placeholder="blurred"
                                />

                                <div className="wd-media-caption">
                                    <span>Typesetting</span>
                                    <img src={WrenchIcon}
                                        alt="🔧"
                                        loading="lazy"
                                        draggable="false"
                                        className="icon"
                                    />
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </section>

                {/* I  N  T  E  R  F  A  C  E */}
                <motion.section
                    className="wd-block interface"
                    ref={interfaceRef}
                    initial={{ y: 20, opacity: 0 }}
                    whileInView={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.4,
                            ease: "circOut",
                        }
                    }}
                    viewport={{
                        once: true,
                        root: interfaceRef.current,
                        margin: "0% 0% -10% 0%",
                    }}
                >
                    <div className="wd-cc">
                        <div className="title">
                            <h2>Interface Design</h2>
                            <img src={RacingCarIcon}
                                alt="🏎️"
                                className="icon"
                                loading="eager"
                                draggable="false" />
                        </div>
                        <div className="copy">
                            <p className="highlight">
                                Blockchain and Blockchain Co <br />Since 21st century
                            </p>
                            <span className="divider"></span>
                            <p>
                                You are familiar with the greenroom drama from the previous sections. This is what the performance finally looked like.
                                <br /><br />
                                Since this company was in the emerging technology space, the website had to work the part. Apart from the usual pages, we designed an 8-bit-ticker and separate technology pages that serve as knowledge banks.
                            </p>
                        </div>
                    </div>

                    <div className="wd-media-wrapper">
                        <div className="flickity-controls">
                            <button className="previous" onClick={() => galleryRef.current.previous()} aria-label="Previous"></button>
                            <button className="next" onClick={() => galleryRef.current.next()} aria-label="Next"></button>
                        </div>

                        <Flickity
                            flickityRef={flkty => galleryRef.current = flkty}
                            className={'wd-gallery'}
                            options={galleryOptions}
                            static={true}
                        >
                            <StaticImage
                                src="../../images/work/codezeros/8a.jpg"
                                width={1600}
                                alt="Another landing page screen"
                                className="wd-media-img"
                                draggable="false"
                                placeholder="blurred"
                            />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={HomepageScrollVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={TechnologySectionVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={ServicesListVideo} />

                            <video
                                draggable="false"
                                autoPlay="autoplay"
                                playsInline="playsinline"
                                muted="muted"
                                loop="loop"
                                src={HeroSectionVideo} />

                        </Flickity>

                        <div className="wd-gallery-caption">
                            <p className="caption-text">
                                {galleryCaptions[galleryIndex]}
                            </p>
                            <div className="divider"></div>
                            <p className="caption-counter">
                                {galleryIndex + 1}/{galleryCaptions.length}
                            </p>
                        </div>
                    </div>
                </motion.section>

                {/* F  O  O  T  N  O  T  E */}
                <section className="wd-block footnote" ref={footnoteRef}>
                    <motion.div
                        className="wd-footnote-card"
                        variants={fadeVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            root: footnoteRef.current,
                            margin: "0% 0% -10% 0%",
                        }}
                    >
                        <div className="title">
                            <h2>
                                Stable, <br />emerging tech
                                <span className="title-toys">
                                    <img src={CodezerosBrandmark}
                                        alt=""
                                        draggable="false"
                                        loading="lazy" />
                                </span>
                            </h2>
                            <p>
                                Making something look pretty is easy in the 21st century, especially in the technology space. But to make something truly of the future, it requires looking in simpler spaces.
                            </p>
                        </div>
                        <div className="copy">
                            <p>
                                In 2018 we didn't have much to work with except the knowledge of the technology itself. It gave us an insight into how future of technology is shaped in the present, regardless of era. Using this we focused on sowing the culture and what we got from the culture, we used in shaping the external brand voice. The branding program made internal communication more frequent, pivoted teams towards a more agile workflow, and made them lean towards constant learning.
                            </p>
                        </div>
                        <div className="media">
                            <StaticImage
                                src="../../images/work/codezeros/7.jpg"
                                width={1900}
                                alt="Internal signage inside one of codezeros offices"
                                className="wd-media-img"
                                loading="lazy"
                                draggable="false"
                                placeholder="blurred"
                            />
                        </div>
                    </motion.div>
                </section>

                {/* O  U  T  R  O */}
                <section className="wd-block outro" ref={outroRef}>
                    <WorkOutro type="branding" referrerPath="codezeros" />
                </section>

            </article >

            <div className="wd-progress codezeros" ref={workProgressBar}>
                <p>
                    <span>{getSplitText(scrollProgress)[0]}</span>{getSplitText(scrollProgress)[1]}
                </p>
            </div>

            <Footer
                furtherReadingLinks={true}
            />

        </Styleswrapper >
    );
}

export default Codezeros;
